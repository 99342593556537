/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

import { IRequestOptions, IRequestConfig, getConfigs, axios } from './serviceOptions';
export const basePath = '';

export interface IList<T> extends Array<T> { }
export interface List<T> extends Array<T> { }
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> { }

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AffService {
  /**
   * Danh sách CTV
   */
  static getByConditions(
    params: {
      /** Tìm theo số thẻ, stk thẻ */
      generalSearch?: string;
      /** Trạng thái */
      status?: number;
      /** Trang số bao nhiêu */
      pageIndex?: number;
      /** Bao nhiêu bản ghi */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AffViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Aff/GetByConditions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        Status: params['status'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm aff
   */
  static addOrEdit(
    params: {
      /**  */
      body?: CreateAffAPI;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Aff/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa aff
   */
  static deleteAff(
    params: {
      /** ID aff */
      affId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Aff/DeleteAff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { affID: params['affId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class AppService {
  /**
   *
   */
  static getShopActive(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/App/GetShopActive';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInforUser(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/App/GetInforUser';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editUser(
    params: {
      /**  */
      body?: EditUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/App/EditUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logActionUser(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/App/LogActionUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class BankCardService {
  /**
   * Danh sách thẻ
   */
  static getByConditions(
    params: {
      /** Tìm theo số thẻ,chủ thẻ */
      generalSearch?: string;
      /** Trạng thái */
      status?: number;
      /** Trang số bao nhiêu */
      pageIndex?: number;
      /** Bao nhiêu bản ghi */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BankCardCreateItemListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/BankCard/GetByConditions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        Status: params['status'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm ,update thẻ
   */
  static addOrEdit(
    params: {
      /**  */
      body?: BankCardCreateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/BankCard/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa thẻ
   */
  static deleteBankCard(
    params: {
      /** BankCardID */
      bankCardId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/BankCard/DeleteBankCard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { BankCardID: params['bankCardId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CardDataService {
  /**
   *
   */
  static getByConditions(
    params: {
      /**  */
      generalSearch?: string;
      /**  */
      customerId?: number;
      /**  */
      status?: number;
      /**  */
      note?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      dateDues?: string;
      /**  */
      cardInShop?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CardDataViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/GetByConditions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        CustomerID: params['customerId'],
        Status: params['status'],
        Note: params['note'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        DateDues: params['dateDues'],
        cardInShop: params['cardInShop']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm thẻ
   */
  static addOrEdit(
    params: {
      /**  */
      body?: CreateCardDataRequestAPI;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa thẻ
   */
  static deleteCard(
    params: {
      /** ID thẻ */
      cardDataId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/DeleteCard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cardDataID: params['cardDataId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Chuyển trạng thái giữ hủy thẻ
   */
  static changeHoldCard(
    params: {
      /** ID thẻ */
      cardDataId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/ChangeHoldCard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cardDataID: params['cardDataId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách data thẻ theo khách hàng
   */
  static getByCustomerId(
    params: {
      /** Search theo KH */
      customerId?: number;
      /** Trang số bao nhiêu */
      pageIndex?: number;
      /** Bao nhiêu bản ghi */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CardDataViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/GetByCustomerID';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CustomerID: params['customerId'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * DS hđ cần xử lý
   */
  static getNotifyDate(
    params: {
      /**  */
      generalSearch?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      dateDues?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CardDataViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/GetNotifyDate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        DateDues: params['dateDues']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Số lượng cảnh báo
   */
  static getCountNoti(options: IRequestOptions = {}): Promise<CountNotifyResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/GetCountNoti';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách data thẻ heest han
   */
  static getCardExpired(
    params: {
      /** Trang số bao nhiêu */
      pageIndex?: number;
      /** Bao nhiêu bản ghi */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CardDataViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/GetCardExpired';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PageIndex: params['pageIndex'], PageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * upload thẻ khách hàng
   */
  static addListCard(
    params: {
      /**  */
      body?: CreateCardDataRequest[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/AddListCard';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sach thẻ của cửa hàng
   */
  static cardDataCustomerByShop(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/CardDataCustomerByShop';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCardDataExcel(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/GetCardDataExcel';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đặt lịch hẹn thanh toán
   */
  static addCalendarPayment(
    params: {
      /**  */
      body?: AddCalendarPaymentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/AddCalendarPayment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách lịch hẹn
   */
  static getCalendarPayment(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/GetCalendarPayment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy datacardtheo ID
   */
  static getById(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CardDataViewResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/GetByID';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ID: params['id'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteCardNotify(
    params: {
      /**  */
      cardDataId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeleteCardDataNotifyResponseResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CardData/DeleteCardNotify';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CardDataID: params['cardDataId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerService {
  /**
   * Lấy danh sách KH
   */
  static ajaxLoadCustomer(
    params: {
      /** Tên hoặc SĐT */
      generalSearch?: string;
      /** Trạng thái, all là -1 , active là 1 , khóa là 0 */
      status?: number;
      /** Trang số bao nhiêu */
      pageIndex?: number;
      /** Lấy bao bản ghi */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Customer/AjaxLoadCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        Status: params['status'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm KH
   */
  static addOrEditCustomer(
    params: {
      /**  */
      body?: CustomerCreate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Customer/AddOrEditCustomer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa khách hàng
   */
  static deleteCustomer(
    params: {
      /** ID KH */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Customer/DeleteCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CustomerID: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy khách hàng dự kiến
   */
  static getCustomerSearch(
    params: {
      /** Tên hoặc SĐT */
      generalSearch?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Customer/GetCustomerSearch';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { GeneralSearch: params['generalSearch'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class DebtService {
  /**
   * Danh sách công nợ / tổng kết công nợ
   */
  static getSummaryDebt(
    params: {
      /** Ten KH */
      generalSearch?: string;
      /** -1 : Tất cả . 1 : Khách hàng . 2: Máy pos */
      type?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /** Loại -1 : tất cả . 1 : Phải thu . 2 : Phải trả */
      typeDebt?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppSummaryDebtListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/GetSummaryDebt';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        generalSearch: params['generalSearch'],
        type: params['type'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        typeDebt: params['typeDebt']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Chi tiết công nợ tổng kết
   */
  static detailDebt(
    params: {
      /** ID KH */
      customerId?: number;
      /** ID máy pos */
      posId?: number;
      /** ID affID */
      affId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      partnerId?: number;
      /**  */
      searchShopId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppDetailDebtByCustomerListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/DetailDebt';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        customerID: params['customerId'],
        posID: params['posId'],
        affID: params['affId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        partnerID: params['partnerId'],
        searchShopID: params['searchShopId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static runDebt(
    params: {
      /**  */
      shopId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/RunDebt';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { shopID: params['shopId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Điều chỉnh công nợ
   */
  static adjustDebt(
    params: {
      /**  */
      body?: AppAdjustDebt;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/AdjustDebt';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thanh toán công nợ
   */
  static payDebtCustomer(
    params: {
      /**  */
      body?: AppPayDebtCustomer;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/PayDebtCustomer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đối chiếu công nợ
   */
  static ajaxGetGeneralComparison(
    params: {
      /** Từ ngày */
      strFromDate?: string;
      /** Đến ngày */
      strToDate?: string;
      /** pageIndex */
      pageIndex?: number;
      /** pageSize */
      pageSize?: number;
      /** tên/SĐT KH */
      generalSearch?: string;
      /** -1 : Tất cả . 1 : Khách hàng . 2: Đối tác */
      type?: number;
      /** Loại nợ -1 : tất cả . 1 : Phải thu . 2 : Phải trả */
      typeDebt?: number;
      /**  */
      showAllDebt?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppGeneralComparisionListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/AjaxGetGeneralComparison';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        strFromDate: params['strFromDate'],
        strToDate: params['strToDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        generalSearch: params['generalSearch'],
        type: params['type'],
        typeDebt: params['typeDebt'],
        showAllDebt: params['showAllDebt']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Công nợ máy Pos
   */
  static debtPos(
    params: {
      /** Từ ngày */
      fromDate?: string;
      /** đến ngày */
      toDate?: string;
      /** PosId */
      posId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/DebtPos';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { FromDate: params['fromDate'], ToDate: params['toDate'], PosId: params['posId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelPayDebt(
    params: {
      /**  */
      debtId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/CancelPayDebt';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { DebtID: params['debtId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách cashback
   */
  static getAllPosCashBackCompany(
    params: {
      /**  */
      posName?: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/GetAllPosCashBackCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { posName: params['posName'], FromDate: params['fromDate'], ToDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm cashback
   */
  static addCashBack(
    params: {
      /**  */
      body?: AddCashBackRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/AddCashBack';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thanh toán công nợ
   */
  static payCashBack(
    params: {
      /**  */
      body?: PayCashBackRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/PayCashBack';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy thanh toán
   */
  static cancelPayCashBack(
    params: {
      /**  */
      paymentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/CancelPayCashBack';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { paymentID: params['paymentId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lịch sử thanh toán tiền
   */
  static getHistoryPayCashBack(
    params: {
      /**  */
      posParentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/GetHistoryPayCashBack';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { posParentID: params['posParentId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Tạo số lô
   */
  static createNumberLo(
    params: {
      /**  */
      body?: AddNumberLoRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/CreateNumberLo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * danh sách số lô
   */
  static getNumberLoConditions(
    params: {
      /**  */
      generalSearch?: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      posParentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/GetNumberLoConditions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        generalSearch: params['generalSearch'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        posParentID: params['posParentId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Tổng kết số lô
   */
  static addBillNumberLo(
    params: {
      /**  */
      body?: AddBillNumberLoRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/AddBillNumberLo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách hóa đơn dùng để tổng kết số lô
   */
  static getBillNumberLo(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      numberLoId?: number;
      /**  */
      numberText?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/GetBillNumberLo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        numberLoID: params['numberLoId'],
        numberText: params['numberText']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thanh toán số lô
   */
  static addPayNumberLo(
    params: {
      /**  */
      body?: PayNumberLoRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/AddPayNumberLo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lịch sử thanh toán số lô
   */
  static getHistoryPayNumberLo(
    params: {
      /**  */
      numberLoId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/GetHistoryPayNumberLo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { numberLoID: params['numberLoId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * hủy thanh toán số lô
   */
  static cancelPayNumberLo(
    params: {
      /**  */
      payNumberLoId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/CancelPayNumberLo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { payNumberLoID: params['payNumberLoId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Cập nhật số lô
   */
  static updateNumberLo(
    params: {
      /**  */
      body?: UpdateNumberLoRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/UpdateNumberLo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteNumberLo(
    params: {
      /**  */
      numberLoId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/DeleteNumberLo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { numberLoID: params['numberLoId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reloadProcessDebt(
    params: {
      /**  */
      customerId?: number;
      /**  */
      shopId?: number;
      /**  */
      fromDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/ReloadProcessDebt';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerID: params['customerId'], shopID: params['shopId'], fromDate: params['fromDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reloadProcessDebtPos(
    params: {
      /**  */
      posId?: number;
      /**  */
      shopId?: number;
      /**  */
      fromDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/ReloadProcessDebtPos';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { posID: params['posId'], shopID: params['shopId'], fromDate: params['fromDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reloadBankCard(
    params: {
      /**  */
      bankcardId?: number;
      /**  */
      shopId?: number;
      /**  */
      fromDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/ReloadBankCard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { bankcardID: params['bankcardId'], shopID: params['shopId'], fromDate: params['fromDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static debtByCard(
    params: {
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/DebtByCard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerID: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static payDebtCustomerWithCard(
    params: {
      /**  */
      body?: AppPayDebtCustomerByCardRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/PayDebtCustomerWithCard';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static payDebtFromPdf(
    params: {
      /**  */
      body?: AddDebtPosPDFRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/PayDebtFromPDF';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeStatusPaymentPdf(
    params: {
      /**  */
      paymentId?: number;
      /**  */
      scheduleId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/ChangeStatusPaymentPDF';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { paymentID: params['paymentId'], scheduleID: params['scheduleId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thanh toán công nợ
   */
  static payDebtPartnerSchedule(
    params: {
      /**  */
      body?: AppPayDebtPartnerSchedule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/PayDebtPartnerSchedule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addLogAdjustDebtSchedule(
    params: {
      /**  */
      body?: AddLogAdjustDebtScheduleRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Debt/AddLogAdjustDebtSchedule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ExpenseCardService {
  /**
   * Danh sách
   */
  static getByConditions(
    params: {
      /**  */
      generalSearch?: string;
      /**  */
      status?: number;
      /** truyền format date */
      fromDate?: string;
      /** truyền format date */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExpenseCardResponseAPIListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ExpenseCard/GetByConditions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        Status: params['status'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * hủy
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ExpenseCard/Delete';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Tạo
   */
  static add(
    params: {
      /**  */
      body?: RequestAddExpenseCard;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ExpenseCard/Add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class FileService {
  /**
   * Upload chứng từ
   */
  static uploadImage(
    params: {
      /**  */
      image?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/File/UploadImage';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['image']) {
        if (Object.prototype.toString.call(params['image']) === '[object Array]') {
          for (const item of params['image']) {
            data.append('image', item as any);
          }
        } else {
          data.append('image', params['image'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadImagePdf(
    params: {
      /**  */
      posid?: number;
      /**  */
      bankId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      file?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/File/UploadImagePDF';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = {
        posid: params['posid'],
        bankID: params['bankId'],
        fromDate: params['fromDate'],
        toDate: params['toDate']
      };

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LogActionService {
  /**
   * thêm log action
   */
  static add(
    params: {
      /** 1- data thẻ ,2 -đơn hàng ,3-thu chi ,4- đối tác q uẹt ,5-công nợ,6- tiền ứng */
      menuId?: number;
      /** ghi chú */
      note?: string;
      /** 1-thêm,2- sửa ,3-xóa */
      actionId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/LogAction/Add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { MenuID: params['menuId'], Note: params['note'], ActionID: params['actionId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * danh sách log action của shop
   */
  static getLogAction(
    params: {
      /** Từ ngày */
      fromDate?: string;
      /** Đến ngày */
      toDate?: string;
      /** 1- data thẻ ,2 -đơn hàng ,3-thu chi ,4- đối tác quẹt ,5-công nợ,6- tiền ứng */
      menuId?: number;
      /** 1-thêm,2- sửa ,3-xóa */
      actionId?: number;
      /** số trang */
      pageIndex?: number;
      /** số bản ghi */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/LogAction/GetLogAction';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        MenuId: params['menuId'],
        ActionID: params['actionId'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ManualDataBillService {
  /**
   *
   */
  static addDataManualBill(
    params: {
      /**  */
      body?: RequestManualInputData;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ManualDataBill/AddDataManualBill';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteManualBill(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ManualDataBill/DeleteManualBill';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByConditions(
    params: {
      /**  */
      generalSearch?: string;
      /**  */
      partnerId?: number;
      /**  */
      posParentId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ManualDataBill/GetByConditions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        PartnerID: params['partnerId'],
        PosParentID: params['posParentId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class MetaService {
  /**
   * Meta -  Danh sách nhân viên ( không có tài khoản chủ )
   */
  static getAllStaff(options: IRequestOptions = {}): Promise<MetaStaffListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAllStaff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy tài khoản của cửa hàng
   */
  static getAllUser(options: IRequestOptions = {}): Promise<MetaStaffListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAllUser';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta - danh sách ngân hàng
   */
  static getAllBank(options: IRequestOptions = {}): Promise<MetaBankListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAllBank';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy thẻ của cửa hàng
   */
  static getCardDataShop(options: IRequestOptions = {}): Promise<SelectModelCardListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetCardDataShop';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy toàn bộ máy pos
   */
  static getAllPos(options: IRequestOptions = {}): Promise<SelectModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAllPos';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy toàn CTV
   */
  static getAff(options: IRequestOptions = {}): Promise<SelectModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy % của CTV theo loại thẻ
   */
  static percentAff(
    params: {
      /**  */
      affId?: number;
      /**  */
      typeCard?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MetaPercentAffResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/PercentAff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { AffID: params['affId'], TypeCard: params['typeCard'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Upload chứng từ
   */
  static readBill(
    params: {
      /**  */
      image?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/ReadBill';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['image']) {
        if (Object.prototype.toString.call(params['image']) === '[object Array]') {
          for (const item of params['image']) {
            data.append('image', item as any);
          }
        } else {
          data.append('image', params['image'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllPosCompany(options: IRequestOptions = {}): Promise<SelectModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAllPosCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class PartnerService {
  /**
   * Lấy danh sách KH
   */
  static ajaxLoadPartner(
    params: {
      /** Tên hoặc SĐT */
      generalSearch?: string;
      /** Trạng thái, all là -1 , active là 1 , khóa là 0 */
      status?: number;
      /** Trang số bao nhiêu */
      pageIndex?: number;
      /** Lấy bao bản ghi */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Partner/AjaxLoadPartner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        Status: params['status'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm KH
   */
  static addOrEdit(
    params: {
      /**  */
      body?: CustomerCreate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Partner/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static importPartnerExcel(
    params: {
      /**  */
      body?: ImportPartnerExcelRequest[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Partner/ImportPartnerExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa khách hàng
   */
  static delete(
    params: {
      /** ID KH */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Partner/Delete';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CustomerID: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy đối tác của cửa hàng
   */
  static getAllPartner(options: IRequestOptions = {}): Promise<CustomerViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Partner/GetAllPartner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm cấu hình phí đối tác với cửa hàng
   */
  static configFeePartnerShop(
    params: {
      /**  */
      body?: PosFeePartner;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Partner/ConfigFeePartnerShop';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * /Lấy danh sách cấu hình phí khách hàng
   */
  static getConfigPartnerFeeShop(
    params: {
      /**  */
      partnenrId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Partner/GetConfigPartnerFeeShop';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        partnenrID: params['partnenrId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa cấu hình
   */
  static deleteConfigFee(
    params: {
      /** ID cấu hình */
      configPartnerFeeShopId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Partner/DeleteConfigFee';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ConfigPartnerFeeShopID: params['configPartnerFeeShopId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getConfigFeePartner(
    params: {
      /**  */
      posId?: number;
      /**  */
      partnerId?: number;
      /**  */
      typeCard?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Partner/GetConfigFeePartner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { posID: params['posId'], partnerID: params['partnerId'], typeCard: params['typeCard'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class PaymentService {
  /**
   * DS Cấu hình phiếu thu chi khác
   */
  static getConfigCollectionExpense(
    params: {
      /** Tên phiếu */
      name?: string;
      /** -1 : tất cả ,  20 phiếu thu , 105 : phiếu chi */
      type?: number;
      /** -1 tất cả ,1  hoạt động , 0 khóas */
      status?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppConfigCollectExpenseListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/GetConfigCollectionExpense';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        name: params['name'],
        type: params['type'],
        status: params['status'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm mới cấu hình
   */
  static createOrUpdateConfig(
    params: {
      /**  */
      body?: AppCreateConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/CreateOrUpdateConfig';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm mới phiếu thu
   */
  static payCollectOther(
    params: {
      /**  */
      body?: AppCreateCollectExpense;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/PayCollectOther';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm mới phiếu chi
   */
  static payExpenseOther(
    params: {
      /**  */
      body?: AppCreateCollectExpense;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/PayExpenseOther';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy phiếu thu chi
   */
  static cancelPaymentExpenseCollection(
    params: {
      /** ID của bản ghi */
      paymentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/CancelPaymentExpenseCollection';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { paymentID: params['paymentId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * DS Thu chi khác (status != 0 và paymentType != 109 và paymentType != 21 thì hiển thị nút hủy)
   */
  static detailCollectExpense(
    params: {
      /** Mã phiếu */
      codeText?: string;
      /** Tên,SĐT */
      generalSearch?: string;
      /** Loại phiếu : -1 tất cả , 1 phiếu thu, 2 phiếu chỉ */
      typeId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /** loại phiếu chi tiết */
      detailType?: number;
      /**  */
      bankCardId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppCashBookDataListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/DetailCollectExpense';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        codeText: params['codeText'],
        generalSearch: params['generalSearch'],
        typeID: params['typeId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        detailType: params['detailType'],
        bankCardID: params['bankCardId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy loại phiếu thu chi
   */
  static getConfigByType(
    params: {
      /** type = 20 là phiếu thu, 105 phiếu chi */
      type?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SelectModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/GetConfigByType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { type: params['type'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reloadMoney(
    params: {
      /**  */
      userId?: number;
      /**  */
      fromDate?: string;
      /**  */
      shopId?: number;
      /**  */
      bankCardId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/ReloadMoney';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        userID: params['userId'],
        fromDate: params['fromDate'],
        shopID: params['shopId'],
        bankCardID: params['bankCardId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class PosService {
  /**
   * Danh sách Máy POS
   */
  static getByConditions(
    params: {
      /** Tìm theo số thẻ, stk thẻ */
      generalSearch?: string;
      /** Trạng thái */
      status?: number;
      /** ID đối tác - 1 là tất cả , 0 là của cửa hàng , > 0 là đối tác */
      partnerId?: number;
      /** Trang số bao nhiêu */
      pageIndex?: number;
      /** Bao nhiêu bản ghi */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PosViewAPIListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Pos/GetByConditions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        Status: params['status'],
        PartnerID: params['partnerId'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm máy pos
   */
  static addOrEdit(
    params: {
      /**  */
      body?: PosCreateAPI;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Pos/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static importPosExcel(
    params: {
      /**  */
      body?: ImportPosExcelRequest[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Pos/ImportPosExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa máy pos
   */
  static delete(
    params: {
      /** ID posID */
      posId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Pos/Delete';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { posID: params['posId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm cấu hình máy pos với đối tác
   */
  static createPosPartner(
    params: {
      /**  */
      body?: CreatePosPartner;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Pos/CreatePosPartner';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lịch sử cấu hình máy pos
   */
  static getPosPartner(
    params: {
      /**  */
      parterId?: number;
      /**  */
      posId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PosPartnerViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Pos/GetPosPartner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        parterID: params['parterId'],
        posID: params['posId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa cấu hình pos + đối tái
   */
  static deletePosPartner(
    params: {
      /** ID posPartnerID */
      posPartnerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Pos/DeletePosPartner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { posPartnerID: params['posPartnerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy chi tiết bill
   */
  static getBills(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Pos/GetBills';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * update trạng thái  LogPosCodePartner
   */
  static updateStatusLogPosCodePartner(
    params: {
      /**  */
      body?: UpdateStatusLogPosCodePartnerRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Pos/UpdateStatusLogPosCodePartner';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * danh sách LogPosCodePartner
   */
  static getLogPosCodePartner(
    params: {
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Pos/GetLogPosCodePartner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { pageIndex: params['pageIndex'], pageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * data pos đối tác
   */
  static dataPosPartner(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      userPartner?: string;
      /**  */
      posSearch?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Pos/DataPosPartner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        userPartner: params['userPartner'],
        posSearch: params['posSearch']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static wrongSwipeRuleWarning(
    params: {
      /**  */
      posId?: number;
      /**  */
      money?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Pos/WrongSwipeRuleWarning';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PosID: params['posId'], Money: params['money'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ReportService {
  /**
   * Báo cáo doanh thu
   */
  static getReportInterest(
    params: {
      /** Tên KH */
      generalSearch?: string;
      /** từ ngày */
      fromDate?: string;
      /** đến ngày */
      toDate?: string;
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppReportInterestListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/GetReportInterest';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        customerID: params['customerId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSummaryReportInterest(
    params: {
      /**  */
      generalSearch?: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppReportInterestListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/GetSummaryReportInterest';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        fromDate: params['fromDate'],
        toDate: params['toDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * BẢNG TỔNG KẾT
   */
  static summaryDebt(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      bankCardId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppSummaryCashBookResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/SummaryDebt';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], bankCardID: params['bankCardId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static summaryDebtCompany(
    params: {
      /**  */
      codeText?: string;
      /**  */
      generalSearch?: string;
      /**  */
      typeId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      bankcardId?: number;
      /**  */
      shopId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppSummaryCashBookResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/SummaryDebtCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        codeText: params['codeText'],
        generalSearch: params['generalSearch'],
        typeID: params['typeId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        bankcardID: params['bankcardId'],
        shopID: params['shopId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Chi tiết sổ quỹ
   */
  static detailCashBook(
    params: {
      /** Mã phiếu */
      codeText?: string;
      /** Tên,SĐT */
      generalSearch?: string;
      /** Loại phiếu : -1 tất cả , 1 phiếu thu, 2 phiếu chỉ */
      typeId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      bankcardId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppCashBookDataListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/DetailCashBook';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        codeText: params['codeText'],
        generalSearch: params['generalSearch'],
        typeID: params['typeId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        bankcardID: params['bankcardId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static detailCashBookCompany(
    params: {
      /**  */
      codeText?: string;
      /**  */
      generalSearch?: string;
      /**  */
      typeId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      bankcardId?: number;
      /**  */
      shopId?: number;
      /**  */
      note?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppCashBookDataListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/DetailCashBookCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        codeText: params['codeText'],
        generalSearch: params['generalSearch'],
        typeID: params['typeId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        bankcardID: params['bankcardId'],
        shopID: params['shopId'],
        note: params['note']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Tổng tiền tất cả
   */
  static reportAllShop(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportAllShopListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ReportAllShop';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đẩy tiền cho nhân viên làm
   */
  static pushMoneyBeginDay(
    params: {
      /**  */
      body?: RequestMoneyBegin;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/PushMoneyBeginDay';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * hoàn tiền
   */
  static pushMoneyBackDay(
    params: {
      /**  */
      body?: RequestMoneyBack;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/PushMoneyBackDay';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listMoneyBeginDay(
    params: {
      /**  */
      fromdate?: string;
      /**  */
      todate?: string;
      /**  */
      shopId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ListMoneyBeginDay';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { fromdate: params['fromdate'], todate: params['todate'], shopID: params['shopId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportMoneyBeginDay(
    params: {
      /**  */
      fromdate?: string;
      /**  */
      todate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ReportMoneyBeginDay';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { fromdate: params['fromdate'], todate: params['todate'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Trang chủ
   */
  static history(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/History';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo thu tiền của kh
   */
  static collectionHistoryCustomer(
    params: {
      /** Từ ngày */
      fromDate?: string;
      /** Đến ngày */
      toDate?: string;
      /** SDT */
      phone?: string;
      /** Tên kh */
      customerName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/CollectionHistoryCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        Phone: params['phone'],
        CustomerName: params['customerName']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * lịch sử đáo thẻ của khách hàng .
   */
  static customerCardHistory(
    params: {
      /** mã khách hàng */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/CustomerCardHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CustomerID: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * báo cáo doanh theo ngày
   */
  static revenueByDay(
    params: {
      /** Từ ngày */
      fromDate?: string;
      /** Đến ngày */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/RevenueByDay';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { FromDate: params['fromDate'], ToDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * báo cáo chi tiết doanh theo ngày
   */
  static detailsRevenueByDay(
    params: {
      /** Từ ngày */
      fromDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/DetailsRevenueByDay';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { FromDate: params['fromDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * báo cáo chi tiết đơn hàng cửa hàng
   */
  static getScheduleShopOfCompanyByDate(
    params: {
      /** Ngày */
      forDate?: string;
      /** trang số */
      pageIndex?: number;
      /** số dòng hiển thị trên table */
      pageSize?: number; shopId?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppScheduleListListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/GetScheduleShopOfCompanyByDate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ForDate: params['forDate'], ShopID: params['shopId'], PageIndex: params['pageIndex'], PageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportDebtPosCompany(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      shopId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportDebtCompanyListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ReportDebtPosCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], shopID: params['shopId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static detailReportDebtPosCompany(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      shopId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppGeneralComparisionListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/DetailReportDebtPosCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        shopID: params['shopId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportDebtCustomerCompany(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      shopId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportDebtCompanyListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ReportDebtCustomerCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], shopID: params['shopId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportDebtPartnerCompany(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      shopId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportDebtCompanyListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ReportDebtPartnerCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], shopID: params['shopId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportDebtAffCompany(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      shopId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportDebtCompanyListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ReportDebtAffCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], shopID: params['shopId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static detailReportDebtCustomerompany(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      shopId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppGeneralComparisionListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/DetailReportDebtCustomerompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        shopID: params['shopId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static detailReportDebtPartnerCompany(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      shopId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppGeneralComparisionListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/DetailReportDebtPartnerCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        shopID: params['shopId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static detailReportDebtAffCompany(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      shopId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppGeneralComparisionListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/DetailReportDebtAffCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        shopID: params['shopId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportCompanyDetailCollectExpense(
    params: {
      /**  */
      typeId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      shopId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppCashBookDataListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ReportCompanyDetailCollectExpense';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        typeID: params['typeId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        shopID: params['shopId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportMoneyCompany(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseReportMoneyCompanyResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ReportMoneyCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportCompanySaleing(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportSaleCompanyResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ReportCompanySaleing';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * back tiền từ giao dịch thu tiền
   */
  static backMoneyFromCustomerPay(
    params: {
      /**  */
      body?: BackMoneyFromCustomer;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/BackMoneyFromCustomerPay';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getHistoryBackMoney(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/GetHistoryBackMoney';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelBackMoneyCustomerPay(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/CancelBackMoneyCustomerPay';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBillOfPosForPdf(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      posId?: number;
      /**  */
      isPdfVeriy?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      bankId?: number;
      /**  */
      ownerCard?: string;
      /**  */
      numberLo?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/GetBillOfPosForPDF';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        posID: params['posId'],
        isPdfVeriy: params['isPdfVeriy'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        bankID: params['bankId'],
        OwnerCard: params['ownerCard'],
        NumberLo: params['numberLo']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo bill hệ thống và nhập
   */
  static getBillOfReport(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/GetBillOfReport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getReportScheduleDataOfCompany(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      shopIdSearch?: number;
      /**  */
      generalSearch?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/GetReportScheduleDataOfCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        shopIDSearch: params['shopIdSearch'],
        generalSearch: params['generalSearch'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getReportPartnerQuet(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      generalSearch?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/GetReportPartnerQuet';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        generalSearch: params['generalSearch'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * báo cáo khách hàng mới
   */
  static reportNewCustomer(
    params: {
      /** Từ ngày */
      fromDate?: string;
      /** Đến ngày */
      toDate?: string;
      /** của hàng */
      shopIdSearch?: number;
      /** số trang */
      pageIndex?: number;
      /** số bản ghi */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ReportNewCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        ShopIDSearch: params['shopIdSearch'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * báo cáo nhân viên
   */
  static reportStaff(
    params: {
      /**  */
      staff?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ReportStaff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Staff: params['staff'], fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * báo cáo nhân viên chi tiết
   */
  static reportStaffDetails(
    params: {
      /**  */
      shopIdSearch?: number;
      /**  */
      staffId?: number;
      /**  */
      fromDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ReportStaffDetails';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ShopIDSearch: params['shopIdSearch'],
        StaffId: params['staffId'],
        fromDate: params['fromDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ReportV2Service {
  /**
   * báo cáo doanh thu khách hàng
   */
  static reportCustomerRevenue(
    params: {
      /** cửa hàng */
      shopIdSearch?: number;
      /** khách hàng */
      customerId?: number;
      /** Từ ngày */
      fromDate?: string;
      /** Đến ngày */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ReportV2/ReportCustomerRevenue';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ShopIDSearch: params['shopIdSearch'],
        CustomerId: params['customerId'],
        fromDate: params['fromDate'],
        toDate: params['toDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * báo cáo chi tiết  doanh thu khách hàng
   */
  static reportCustomerRevenueDetails(
    params: {
      /** cửa hàng */
      shopIdSearch?: number;
      /** tên kh */
      customerId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ReportV2/ReportCustomerRevenueDetails';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ShopIDSearch: params['shopIdSearch'],
        CustomerId: params['customerId'],
        fromDate: params['fromDate'],
        toDate: params['toDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * báo cáo khách hàng không quay lại sử dụng dịch vụ
   */
  static reportCustomerNotUseService(
    params: {
      /**  */
      shopIdSearch?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ReportV2/ReportCustomerNotUseService';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ShopIDSearch: params['shopIdSearch'], fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo thu phí khách hàng
   */
  static getReportScheduleFeeFromCard(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      generalSearch?: string;
      /**  */
      isfeeFromCard?: number;
      /**  */
      shopIdSearch?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ReportV2/GetReportScheduleFeeFromCard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        generalSearch: params['generalSearch'],
        isfeeFromCard: params['isfeeFromCard'],
        shopIDSearch: params['shopIdSearch'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportPos(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      posId?: number;
      /**  */
      shopIdSearch?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ReportV2/ReportPos';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        PosId: params['posId'],
        ShopIDSearch: params['shopIdSearch']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportPartnerCorresponding(
    params: {
      /**  */
      partnerName?: string;
      /**  */
      shopIdSearch?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ReportV2/ReportPartnerCorresponding';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PartnerName: params['partnerName'],
        ShopIDSearch: params['shopIdSearch'],
        FromDate: params['fromDate'],
        ToDate: params['toDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportOrder(
    params: {
      /**  */
      customerName?: string;
      /**  */
      ownerCard?: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ReportV2/ReportOrder';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CustomerName: params['customerName'],
        OwnerCard: params['ownerCard'],
        FromDate: params['fromDate'],
        ToDate: params['toDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ScheduleWorkingService {
  /**
   *
   */
  static getScheduleByDate(
    params: {
      /**  */
      generalSearch?: string;
      /**  */
      status?: number;
      /**  */
      forDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      staffId?: number;
      /**  */
      dateDues?: string;
      /**  */
      contractType?: number;
      /**  */
      typeWorking?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppScheduleListListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetScheduleByDate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        Status: params['status'],
        ForDate: params['forDate'],
        ToDate: params['toDate'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        StaffID: params['staffId'],
        DateDues: params['dateDues'],
        Contract_Type: params['contractType'],
        TypeWorking: params['typeWorking']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đẩy list thẻ xử lý theo ngày
   */
  static pushList(
    params: {
      /**  */
      body?: AppPushList;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/PushList';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm đơn xử lý
   */
  static createScheduleManual(
    params: {
      /**  */
      body?: AppCreateSchedule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/CreateScheduleManual';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa đơn
   */
  static delete(
    params: {
      /** ID lịch */
      scheduleId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/Delete';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ScheduleID: params['scheduleId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Comment đơn
   */
  static commentSchedule(
    params: {
      /**  */
      body?: CommentChange;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/CommentSchedule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lịch sử thao tác đơn
   */
  static getHistoryAction(
    params: {
      /**  */
      cardDataId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetHistoryAction';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CardDataID: params['cardDataId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xử lý đơn
   */
  static createPaymentSchedule(
    params: {
      /**  */
      body?: AppCreatePaymentSchedule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/CreatePaymentSchedule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy dữ liệu để push
   */
  static getDataForPushByDate(
    params: {
      /** Từ ngày */
      fromDate?: string;
      /** đến ngày */
      toDate?: string;
      /** ID nhân viên tất cả là -1 */
      staffId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CardDataViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetDataForPushByDate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], staffID: params['staffId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lịch sử xử lý tiền
   */
  static getHistoryPaymentSchedule(
    params: {
      /** ID lịch */
      scheduleId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppReportInterestListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetHistoryPaymentSchedule';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { scheduleID: params['scheduleId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Quẹt tiền đối ứng
   */
  static createPaymentPartnerReciprocal(
    params: {
      /**  */
      body?: PaymentReciprocal;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/CreatePaymentPartnerReciprocal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lịch sử xử lý tiền
   */
  static getHistoryPaymentSchedulePartnerReciprocal(
    params: {
      /** ID lịch */
      scheduleId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerPaymentHistoryScheduleListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetHistoryPaymentSchedulePartnerReciprocal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { scheduleID: params['scheduleId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * KH nạp tiền
   */
  static customerTopUp(
    params: {
      /**  */
      body?: AppCustomerTopUp;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/CustomerTopUp';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy KH nạp tiền
   */
  static cancelCustomerTopUp(
    params: {
      /**  */
      paymentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/CancelCustomerTopUp';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { paymentID: params['paymentId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy giao dịch đóng tiền
   */
  static cancelPaymentSchedule(
    params: {
      /** ID bản ghi */
      paymentScheduleWorkId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/CancelPaymentSchedule';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PaymentScheduleWorkID: params['paymentScheduleWorkId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy tiền theo thẻ
   */
  static getMoneyCardData(
    params: {
      /** ID bản ghi */
      scheduleId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppCustomerMoneyResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetMoneyCardData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ScheduleID: params['scheduleId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * ID thẻ
   */
  static getHistoryMoneyTopup(
    params: {
      /** ID khách hàng */
      customerId?: number;
      /** ID = 0 thì lấy allg */
      scheduleId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HistoryTopUpListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetHistoryMoneyTopup';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CustomerID: params['customerId'], scheduleID: params['scheduleId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xử lý đơn đối tác quẹt
   */
  static createPaymentSchedulePartner(
    params: {
      /**  */
      body?: PaymentPartnerRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/CreatePaymentSchedulePartner';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * chuyển tiền cho KH
   */
  static sentMoneyCustomer(
    params: {
      /**  */
      body?: SentMoneyCustomer;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/SentMoneyCustomer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * ID thẻ
   */
  static getHistorySentMoney(
    params: {
      /** ID banr ghi */
      scheduleId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TblHistorySentBankCardListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetHistorySentMoney';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ScheduleID: params['scheduleId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lịch sử xử lý của khách hàng
   */
  static getScheduleByCardId(
    params: {
      /**  */
      cardId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppScheduleListListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetScheduleByCardID';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CardID: params['cardId'], PageIndex: params['pageIndex'], PageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getScheduleByDatePartner(
    params: {
      /**  */
      generalSearch?: string;
      /**  */
      status?: number;
      /**  */
      forDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      staffId?: number;
      /**  */
      shiftWorking?: number;
      /**  */
      posId?: number;
      /**  */
      contractType?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppScheduleListListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetScheduleByDatePartner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        Status: params['status'],
        ForDate: params['forDate'],
        ToDate: params['toDate'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        StaffID: params['staffId'],
        shiftWorking: params['shiftWorking'],
        posID: params['posId'],
        ContractType: params['contractType']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Quẹt tiền đối ứng mới
   */
  static createPaymentPartnerReciprocalNew(
    params: {
      /**  */
      body?: PaymentReciprocalNew;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/CreatePaymentPartnerReciprocalNew';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Data đơn
   */
  static getSchedulePartnerReciprocal(
    params: {
      /** Tìm theo số thẻ, stk thẻ */
      generalSearch?: string;
      /** Từ ngày */
      fromDate?: string;
      /** Trạng thái */
      status?: number;
      /** đến ngày */
      toDate?: string;
      /** Trang số bao nhiêu */
      pageIndex?: number;
      /** Bao nhiêu bản ghi */
      pageSize?: number;
      /** ID nhân viên */
      partnerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppScheduleListListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetSchedulePartnerReciprocal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        FromDate: params['fromDate'],
        Status: params['status'],
        ToDate: params['toDate'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        PartnerID: params['partnerId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy đơn quẹt đối tác
   */
  static deleteSchedulePartner(
    params: {
      /** ID banr ghi */
      scheduleId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/DeleteSchedulePartner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { scheduleID: params['scheduleId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * hủy chuyển tiền
   */
  static cancelSentMoneyCustomer(
    params: {
      /** ID banr ghi */
      scheduleId?: number;
      /**  */
      historySentBankCardId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/CancelSentMoneyCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { scheduleID: params['scheduleId'], historySentBankCardID: params['historySentBankCardId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy đơn đối ứng
   */
  static deletePartnerReciprocal(
    params: {
      /** ID banr ghi */
      scheduleId?: number;
      /**  */
      paymentScheduleId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/DeletePartnerReciprocal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { scheduleID: params['scheduleId'], paymentScheduleID: params['paymentScheduleId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * danh sách đã quẹt pos trong ngày của máy pos
   */
  static listSwipePos(
    params: {
      /** posID */
      posId?: number;
      /** Từ ngày */
      fromDate?: string;
      /** đến ngày */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/ListSwipePos';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PosId: params['posId'], FromDate: params['fromDate'], ToDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * các đơn hàng  đã xóa
   */
  static getCancelOrder(
    params: {
      /** Từ ngày */
      fromDate?: string;
      /** đến ngày */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppScheduleListListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetCancelOrder';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { FromDate: params['fromDate'], ToDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportCompany(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      shopSearchId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ListReportCompanyListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/ReportCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { FromDate: params['fromDate'], ToDate: params['toDate'], shopSearchID: params['shopSearchId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static highLighSchedule(
    params: {
      /**  */
      scheduleId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/HighLighSchedule';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { scheduleID: params['scheduleId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateNoteSchedule(
    params: {
      /**  */
      body?: UpdateNoteScheduleRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/UpdateNoteSchedule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Canh bao quet the
   */
  static warningSchedule(
    params: {
      /**  */
      generalSearch?: string;
      /**  */
      forDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      staffId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppScheduleListListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/WarningSchedule';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        ForDate: params['forDate'],
        ToDate: params['toDate'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        StaffID: params['staffId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Canh bao quet the cty
   */
  static warningErrorScheduleCompany(
    params: {
      /**  */
      generalSearch?: string;
      /**  */
      forDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      staffId?: number;
      /**  */
      searchShopId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppScheduleListListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/WarningErrorScheduleCompany';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        ForDate: params['forDate'],
        ToDate: params['toDate'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        StaffID: params['staffId'],
        SearchShopID: params['searchShopId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getScheduleByCustomer(
    params: {
      /**  */
      customerSearch?: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetScheduleByCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CustomerSearch: params['customerSearch'],
        FromDate: params['fromDate'],
        ToDate: params['toDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addLogStatusSchedule(
    params: {
      /**  */
      scheduleId?: number;
      /**  */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/AddLogStatusSchedule';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ScheduleID: params['scheduleId'], Status: params['status'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createListSchedule(
    params: {
      /**  */
      body?: CreateListScheduleRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/CreateListSchedule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getListSchedule(
    params: {
      /**  */
      body?: CreateListScheduleResponse[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetListSchedule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addLogWithdrawal(
    params: {
      /**  */
      body?: LogWithdrawalRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/AddLogWithdrawal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogWithdrawalByScheduleId(
    params: {
      /**  */
      scheduleId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/GetLogWithdrawalByScheduleID';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ScheduleID: params['scheduleId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelLogWithdrawal(
    params: {
      /**  */
      scheduleId?: number;
      /**  */
      logWithdrawalId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/CancelLogWithdrawal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { ScheduleID: params['scheduleId'], LogWithdrawalId: params['logWithdrawalId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sentMoneyCustomerWaiting(
    params: {
      /**  */
      body?: SentMoneyCustomer;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/SentMoneyCustomerWaiting';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addPaymentScheduleWaiting(
    params: {
      /**  */
      body?: AddPaymentScheduleWaitingReq;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ScheduleWorking/AddPaymentScheduleWaiting';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ShopService {
  /**
   * Danh sách chi nhánh
   */
  static ajaxLoadShop(
    params: {
      /** Ten chi nhánh */
      shopName?: string;
      /** Trạng thái : 1 hoạt động , -1 tất cả , 0 khóa, -11 hủy */
      status?: number;
      /** PageIndex */
      pageIndex?: number;
      /** PageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppShopViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Shop/AjaxLoadShop';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ShopName: params['shopName'],
        Status: params['status'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm sửa chi nhánh
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppShopAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Shop/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa chi nhánh
   */
  static delete(
    params: {
      /** ID cửa hàng bị xóa */
      shopIdDelete?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Shop/Delete';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ShopIDDelete: params['shopIdDelete'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách cửa hàng cho chuyển cửa hàng
   */
  static getShopActive(options: IRequestOptions = {}): Promise<AppShopViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Shop/GetShopActive';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   * Đăng nhập hệ thống
   */
  static login(
    params: {
      /**  */
      body?: APIUserRequestLogin;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<APIUserLoginResponseResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/Login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đăng kí
   */
  static register(
    params: {
      /**  */
      body?: AppRequestRegister;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/Register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách nhân viên
   */
  static ajaxLoadStaff(
    params: {
      /** Tên tài khoản */
      userName?: string;
      /** Trạng thái : -1 : Tất cả . 1 hoạt động, 0 khóa , -11 hủy */
      status?: number;
      /** -1 : Tất cả . 4 : Kế toán , 5 : lái xe , 6 : phụ xe, 7 khác */
      groupId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppUserViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/AjaxLoadStaff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        UserName: params['userName'],
        Status: params['status'],
        GroupID: params['groupId'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa nhân viên
   */
  static deleteStaff(
    params: {
      /**  */
      staffId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/DeleteStaff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { StaffID: params['staffId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách quyền của nhân viên
   */
  static getPermissionStaff(
    params: {
      /** Id nhân viên lấy từ select box */
      staffId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JSTreeModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/GetPermissionStaff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { StaffID: params['staffId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm quyền cho nhân viên
   */
  static addPermissionStaff(
    params: {
      /**  */
      body?: AppAddPermission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/AddPermissionStaff';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm nhân viên
   */
  static addOrEditStaff(
    params: {
      /**  */
      body?: AppAddStaff;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/AddOrEditStaff';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thay đổi mật khẩu
   */
  static changePassword(
    params: {
      /**  */
      body?: AppChangePassword;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/ChangePassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export interface APIUserLoginResponse {
  /** ID cửa hàng */
  ShopID?: number;

  /** Tên cửa hàng */
  ShopName?: string;

  /**  */
  GroupID?: number;

  /**  */
  lstShop?: ShopUserLogin[];

  /** UserID */
  UserID?: number;

  /** UserName */
  UserName?: string;

  /** Tên KH */
  FullName?: string;

  /** SĐT */
  Phone?: string;

  /** HẾt hạn */
  ExpiredDate?: Date;

  /** Token */
  Token?: string;

  /**  */
  IsHideProfit?: number;

  /**  */
  menuPermissions?: MenuPermission[];

  /**  */
  AccountBank?: string;

  /**  */
  AccountOwner?: string;

  /**  */
  BankID?: number;

  /**  */
  BankCode?: string;

  /**  */
  ShopAddress?: string;

  /**  */
  ShopPhone?: string;
}

export interface APIUserLoginResponseResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: APIUserLoginResponse;
}

export interface APIUserRequestLogin {
  /** Tên đăng nhập */
  UserName?: string;

  /** Mật khẩu */
  Password?: string;
}

export interface AddBillNumberLoRequest {
  /**  */
  NumberLoID?: number;

  /**  */
  BillIds?: number[];
}

export interface AddCalendarPaymentRequest {
  /**  */
  CardDataID?: number;

  /**  */
  ScheduleID?: number;

  /**  */
  CustomerId?: number;

  /**  */
  CustomerName?: string;

  /**  */
  CalendarPaymentTime?: string;

  /**  */
  CalendarPaymentNote?: string;

  /**  */
  TotalMoneyInBill?: number;

  /**  */
  DebtMoney?: number;
}

export interface AddCashBackRequest {
  /**  */
  TotalMoneyInBill?: number;

  /**  */
  MoneyFee?: number;

  /**  */
  PosParentID?: number;

  /**  */
  Note?: string;
}

export interface AddDebtPosPDFRequest {
  /**  */
  PosID?: number;

  /**  */
  TotalMoney?: number;

  /**  */
  Note?: string;

  /**  */
  PaymentIDs?: number[];
}

export interface AddLogAdjustDebtScheduleRequest {
  /**  */
  ScheduleID?: number;

  /**  */
  CustomerID?: number;

  /**  */
  Note?: string;

  /**  */
  AdjustDebtMoney?: number;
}

export interface AddNumberLoRequest {
  /**  */
  Name?: string;

  /**  */
  ShopIds?: number[];

  /**  */
  PosParentID?: number;
}

export interface AddPaymentScheduleWaitingReq {
  /**  */
  PosID?: number;

  /**  */
  ScheduleID?: number;

  /**  */
  TotalMoneyInBill?: number;

  /**  */
  PercentMoney?: number;
}

export interface AffView {
  /** ID CTV */
  AffID?: number;

  /** Tên CTV */
  AffName?: string;

  /** Code */
  AffCode?: string;

  /** Trạng thái */
  Status?: number;

  /** Ngày tạo */
  CreateDate?: Date;

  /** Tên tài khoản */
  AccountName?: string;

  /** SĐT */
  Phone?: string;

  /** Phí thẻ visa */
  PercentVisa?: number;

  /** Phí thẻ master */
  PercentMaster?: number;

  /** Phí thẻ JSC */
  PercentJSC?: number;

  /**  */
  PercentNapas?: number;
}

export interface AffViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: AffView[];
}

export interface AppAddPermission {
  /** List quyền được xử lý */
  lstPermission?: number[];

  /** ID nhân viên */
  StaffID?: number;
}

export interface AppAddStaff {
  /** ID user : 0 là tạo mới >0 là edit */
  UserID?: number;

  /** Tên tài khoản */
  UserName?: string;

  /** Mật khẩu */
  Password?: string;

  /** Tên */
  FullName?: string;

  /** SĐt */
  Phone?: string;

  /** Ghi chú */
  Note?: string;

  /** Trạng thái 1 : hoạt động , 0 là khóa */
  Status?: number;

  /** Nhóm 4 : kế toán , 5 : lái xe , 6 : phụ xe, 7 : khác */
  GroupID?: number;

  /**  */
  ShopID?: number;

  /** hoa hồng */
  Commission?: number;

  /**  */
  ShopIDs?: number[];

  /**  */
  IsHideProfit?: number;
}

export interface AppAdjustDebt {
  /** ID KH */
  CustomerID?: number;

  /** ID máy pos */
  PosID?: number;

  /** Số tiền */
  AdjustDebtMoney?: number;

  /** Ghi chú */
  Note?: string;

  /** Ngày điều chỉnh */
  StrDate?: string;

  /** ID KH */
  PartnerID?: number;

  /**  */
  AffID?: number;
}

export interface AppCashBookData {
  /** Người Nộp/Nhận */
  CustomerName?: string;

  /** Lý do */
  StrType?: string;

  /** Ngày */
  TransactionDate?: Date;

  /** Nhân viên */
  Username?: string;

  /** Số Tiền */
  TotalMoney?: number;

  /** ghi chú */
  Note?: string;

  /** Mã phiếu */
  CodeText?: string;

  /** Loại phiếu */
  TypeName?: string;

  /** Trạng thái */
  StatusName?: string;

  /** ID Loại phiếu */
  TypeID?: number;

  /** ID ngân hàng */
  PaymentID?: number;

  /**  */
  Status?: number;

  /**  */
  PaymentType?: number;

  /**  */
  ShopName?: string;
}

export interface AppCashBookDataListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: AppCashBookData[];
}

export interface AppChangePassword {
  /** Mật khẩu cũ */
  OldPassword?: string;

  /** Mật khẩu mới */
  NewPassword?: string;
}

export interface AppConfigCollectExpense {
  /** ID bản ghi */
  ConfigCollectionExpenseID?: number;

  /** Tên */
  ConfigName?: string;

  /** Loại */
  TypeID?: number;

  /** Trạng thái */
  Status?: number;

  /** Thời gian tạo */
  CreateDate?: Date;

  /** Người tạo */
  CreateUserName?: string;

  /** Loại bằng text */
  StrType?: string;
}

export interface AppConfigCollectExpenseListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: AppConfigCollectExpense[];
}

export interface AppCreateCollectExpense {
  /** Tiền */
  TotalMoney?: number;

  /** PaymentType lấy từ meta - danh sách phiếu */
  PaymentType?: number;

  /** Ghi chú */
  Note?: string;

  /** Tên KH */
  CustomerName?: string;

  /** Ngày giao dịch định dạng dd/MM/yyyy HH:mm */
  TransactionTime?: string;

  /**  */
  BankCardID?: number;

  /**  */
  TypeID?: number;
}

export interface AppCreateConfig {
  /** ID bản ghi */
  ConfigCollectionExpenseID?: number;

  /** Tên */
  ConfigName?: string;

  /** Loại */
  TypeID?: number;

  /** Trạng thái */
  Status?: number;
}

export interface AppCreatePaymentSchedule {
  /** ID KH */
  CustomerID?: number;

  /** ID thẻ */
  CardDataID?: number;

  /** Tổng tiền đã chuyển */
  TotalMoney?: number;

  /** Số tiền trên bill */
  TotalMoneyBill?: number;

  /** % Phí */
  PercentFee?: number;

  /** Phí bằng tiền */
  PercentMoney?: number;

  /** KH trả */
  CustomerPay?: number;

  /** DS pos */
  LstPos?: PosMoney[];

  /** ID đơn */
  ScheduleID?: number;

  /** Tiền khác */
  OtherMoney?: number;

  /** Loại hình 1 là rút tiền, 2 là đáo thẻ */
  ContractType?: number;

  /** Ghi chú */
  Note?: string;

  /** 0 là KH, 1 là ctv */
  DebtForPersion?: number;

  /**  */
  PaymentSchedulePos?: PaymentSchedulePos[];

  /**  */
  TotalMoneySent?: number;

  /**  */
  HistorySendMoneyID?: number;
}

export interface AppCreateSchedule {
  /** ID lich >0 là sửa */
  ScheduleID?: number;

  /** ID khách hàng */
  CustomerID?: number;

  /** Tên KH */
  CustomerName?: string;

  /** Số ĐT */
  CustomerPhone?: string;

  /** Địa chỉ */
  Address?: string;

  /** ID thẻ */
  CardDataID?: number;

  /** Tổng tiền */
  TotalMoney?: number;

  /** % phí */
  PercentFee?: number;

  /** Ngày xử lý */
  ForDate?: string;

  /** Loại hình xử lý 1 : Rút tiền , 2 Đáo thẻ */
  TypePayment?: number;

  /** Tiền phí fix cứng */
  MoneyFee?: number;

  /**  */
  AffID?: number;

  /**  */
  AffFeePercent?: number;

  /**  */
  Note?: string;
}

export interface AppCustomerMoney {
  /**  */
  MoneyInBag?: number;

  /** Số tiền xử lý */
  TotalMoney?: number;

  /** Còn lại */
  TotalMoneyCurrent?: number;

  /** Đã thanh toán */
  ProcessedMoney?: number;

  /** Tiền trên bill */
  MoneyInBill?: number;

  /** Tiền đã chuyển */
  MoneySent?: number;

  /** Số tiền tổng phí */
  TotalMoneyFee?: number;

  /** Số tiền treo âm thẻ */
  TotalMoneyHoldInCard?: number;
}

export interface AppCustomerMoneyResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: AppCustomerMoney;
}

export interface AppCustomerTopUp {
  /** ID KH */
  CustomerID?: number;

  /** Ngày thao tác */
  StrTransactionDate?: string;

  /** Số tiền */
  TotalMoney?: number;

  /** ID bản ghi */
  ScheduleID?: number;
}

export interface AppDetailDebtByCustomer {
  /** Ngày Giao Dịch */
  TransactionDate?: Date;

  /** Mã Chứng Từ */
  CodeText?: string;

  /** Ghi chú */
  Note?: string;

  /** Số Tiền */
  DebtMoney?: number;

  /** Số dư */
  Balance?: number;

  /** Ngày Tạo */
  CreateDate?: Date;

  /** Số dư */
  TotalLastBalance?: number;

  /** Tên KH */
  CustomerName?: string;

  /**  */
  MoneyInBill?: number;

  /**  */
  FeeBill?: number;

  /**  */
  MoneyPayment?: number;

  /**  */
  AdjustMoney?: number;

  /**  */
  FirstBalance?: number;

  /**  */
  ScheduleID?: number;

  /**  */
  CardNumber?: string;

  /**  */
  DebtID?: number;

  /**  */
  ShopName?: string;

  /**  */
  ShiftWorkingName?: string;

  /**  */
  OwnerCard?: string;

  /**  */
  MoneySentBank?: number;

  /**  */
  PaymentID?: number;
}

export interface AppDetailDebtByCustomerListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: AppDetailDebtByCustomer[];
}

export interface AppGeneralComparision {
  /** ID KH */
  CustomerID?: number;

  /**  */
  PartnerID?: number;

  /**  */
  PosID?: number;

  /**  */
  AffID?: number;

  /** Tên KH */
  CustomerName?: string;

  /** SĐT KH */
  CustomerPhone?: string;

  /** Loại KH : 1 cá nhân. 2 đối tác */
  MainType?: number;

  /** Tiền Điều Chỉnh */
  MoneyAdd?: number;

  /** Tiền Thuê Xe */
  MoneySub?: number;

  /** Tiền Đầu Kỳ */
  FirstBalance?: number;

  /**  */
  MoneyInBill?: number;

  /**  */
  FeeBill?: number;

  /** Tiền điều chỉnh */
  AdjustMoney?: number;

  /** Tiền điều chỉnh */
  LastBalance?: number;

  /** Số tổng công nợ */
  TotalMoney?: number;

  /**  */
  PaymentMoney?: number;

  /** Tiền phải trả */
  NeedPay?: number;

  /** Tiền phải trả */
  NeedCollect?: number;

  /**  */
  PosPercent?: number;

  /** Phí thẻ visa */
  PercentVisa?: number;

  /** Phí thẻ master */
  PercentMaster?: number;

  /** Phí thẻ JSC */
  PercentJSC?: number;

  /**  */
  PercentNapas?: number;

  /**  */
  ExpenseMoney?: number;

  /**  */
  PosFee?: number;
}

export interface AppGeneralComparisionListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: AppGeneralComparision[];
}

export interface AppPayDebtCustomer {
  /** ID KH */
  CustomerID?: number;

  /** ID pos */
  PosID?: number;

  /** Số tiền */
  PayDebtMoney?: number;

  /** Ghi chú */
  Note?: string;

  /** Nguồn tiền */
  BankcardID?: number;

  /** AffID pos */
  AffID?: number;

  /** ID KH */
  PartnerID?: number;

  /** Ghi vào chi phí khác */
  IsExpenseOther?: number;

  /**  */
  TypePayment?: number;

  /**  */
  TransactionDate?: string;
}

export interface AppPayDebtCustomerByCardRequest {
  /** ID KH */
  CustomerID?: number;

  /** Số tiền */
  PayDebtMoney?: number;

  /** Ghi chú */
  Note?: string;

  /** Nguồn tiền */
  BankcardID?: number;

  /** Ghi vào chi phí khác */
  IsExpenseOther?: number;

  /** ID hóa đơn */
  ScheduleID?: number;
}

export interface AppPayDebtPartnerSchedule {
  /** ID KH */
  PartnerID?: number;

  /** Số tiền */
  PayDebtMoney?: number;

  /** Ghi chú */
  Note?: string;

  /** Nguồn tiền */
  BankcardID?: number;

  /** Ghi vào chi phí khác */
  IsExpenseOther?: number;

  /** ID hóa đơn */
  ScheduleID?: number;

  /**  */
  TypePayment?: number;
}

export interface AppPushList {
  /** List ID thẻ cần xử lý */
  CardBankIDs?: number[];

  /** Id nhân viên */
  StaffID?: number;
}

export interface AppReportInterest {
  /** ID KH */
  CustomerID?: number;

  /** Tên KH */
  CustomerName?: string;

  /** Nội dung */
  Note?: string;

  /** Tên Ngân hàng */
  BankName?: string;

  /** Phí Ship */
  FeeShip?: number;

  /** Tổng phí */
  TotalMoney?: number;

  /** % phí */
  PercentFee?: number;

  /** Tiền phí */
  MoneyFee?: number;

  /** Tên Pos */
  PosName?: string;

  /** Phí pos % */
  PosFeePercent?: number;

  /** Phí pos */
  PosFee?: number;

  /** Lợi nhuận */
  Profit?: number;

  /** ID bản ghi */
  PaymentScheduleWorkID?: number;

  /** POSID */
  PosID?: number;

  /**  */
  CreateDate?: Date;

  /** Tính phí cho */
  DebtForPersion?: string;

  /**  */
  ScheduleID?: number;

  /**  */
  NumberLo?: number;

  /**  */
  NumberBill?: number;

  /**  */
  Status?: number;
}

export interface AppReportInterestListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: AppReportInterest[];
}

export interface AppRequestRegister {
  /** Tên tài khoản */
  Username?: string;

  /** Mật khẩu */
  Password?: string;

  /** Tên */
  FullName?: string;

  /** SĐT */
  Phone?: string;

  /** Địa chỉ */
  Address?: string;

  /** Cửa hàng */
  ShopName?: string;

  /**  */
  ReferCode?: string;
}

export interface AppScheduleList {
  /** Tên KH */
  CustomerName?: string;

  /** ĐT KH */
  Phone?: string;

  /** Ngân hàng */
  BankName?: string;

  /** Số trên  thẻ */
  NumberCard?: string;

  /** Ngày đến hạn */
  DateDue?: string;

  /** ID bản ghi */
  ScheduleID?: number;

  /** Ngày xử lý */
  ForDate?: Date;

  /** KH */
  CustomerID?: number;

  /** ID thẻ */
  CardDataID?: number;

  /** Tổng tiền */
  TotalMoney?: number;

  /** Phí */
  PercentFee?: number;

  /** Doanh thu dự kiến */
  ExpectedRevenue?: number;

  /** Ngày tạo */
  CreateDate?: Date;

  /** Trạng thái */
  Status?: number;

  /** Comment cuối */
  LastComment?: string;

  /** Tiền chưa xử lý */
  TotalMoneyCurrent?: number;

  /** Tiền đã xử lý */
  MoneyInBill?: number;

  /** Tiền đã xử lý */
  ProcessedMoney?: number;

  /** Loại hình */
  ContractType?: number;

  /**  */
  MoneySentBank?: number;

  /**  */
  OtherMoney?: number;

  /**  */
  PartnerName?: string;

  /**  */
  CustomerPay?: number;

  /**  */
  AffID?: number;

  /**  */
  AffFee?: number;

  /**  */
  PosName?: string;

  /**  */
  FeeCustomer?: number;

  /**  */
  PosFee?: number;

  /**  */
  PartnerFee?: number;

  /**  */
  TypeWorking?: number;

  /**  */
  MoneyInHold?: number;

  /** nhân viên tạo */
  CreateByUser?: string;

  /**  */
  OwnerCard?: string;

  /**  */
  AffFeeMoney?: number;

  /**  */
  ProfitMoney?: number;

  /**  */
  AffMoneyRecevive?: number;

  /**  */
  DetailPosPayment?: ListPosPayment[];

  /**  */
  TotalMoneyOfCard?: number;

  /**  */
  IsShowError?: number;

  /**  */
  TypeCard?: number;

  /**  */
  TypeCardName?: string;

  /**  */
  TotalMoneyHoldInCard?: number;

  /**  */
  IsHighlight?: number;

  /**  */
  Note?: string;

  /**  */
  ShiftWorking?: number;

  /**  */
  ShiftWorkingName?: string;

  /**  */
  ShopName?: string;

  /**  */
  BankNameDoiTacQuet?: string;

  /**  */
  BillDateQuet?: Date;

  /**  */
  LogStatus?: number;

  /**  */
  NumberCode?: string;

  /**  */
  OldMoneyInHold?: number;

  /**  */
  AffName?: string;

  /**  */
  NumberLo?: number;
}

export interface AppScheduleListListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: AppScheduleList[];
}

export interface AppShopAdd {
  /** ID cửa hàng : 0 là tạo , >0 là sửa */
  ShopID?: number;

  /** Tên chi nhánh */
  ShopName?: string;

  /** Địa chỉ */
  Address?: string;

  /** SĐT */
  Phone?: string;

  /** Người đại diện */
  Represent?: string;

  /** Trạng thái : 1 Hoạt động , 0 Khóa */
  Status?: number;

  /** phí về công ty hay cửa hàng */
  FeeShopOrCompany?: number;

  /**  */
  AccountBank?: string;

  /**  */
  AccountOwner?: string;

  /**  */
  BankID?: number;

  /**  */
  BankCode?: string;
}

export interface AppShopView {
  /** ShopID */
  ShopID?: number;

  /** Tên cửa hàng */
  ShopName?: string;

  /** Địa chỉ */
  Address?: string;

  /** SĐT */
  Phone?: string;

  /** Người đại diện */
  Represent?: string;

  /** thời gian tạo */
  CreateDate?: Date;

  /** Trạng thái : 1 hoạt động, 0 khóa , -11 hủy */
  Status?: number;

  /** phí về công ty hay cửa hàng */
  FeeShopOrCompany?: number;

  /**  */
  AccountBank?: string;

  /**  */
  AccountOwner?: string;

  /**  */
  BankID?: number;

  /**  */
  BankCode?: string;

  /**  */
  ShopAddress?: string;

  /**  */
  ShopPhone?: string;
}

export interface AppShopViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: AppShopView[];
}

export interface AppSummaryCashBook {
  /** Thu */
  MoneyCollect?: number;

  /** Chi */
  MoneyExpense?: number;

  /** Đầu kì */
  BeforeMoney?: number;

  /** Cuối kì */
  AfterMoney?: number;
}

export interface AppSummaryCashBookResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: AppSummaryCashBook;
}

export interface AppSummaryDebt {
  /** ID KH */
  CustomerID?: number;

  /** Tên KH */
  CustomerName?: string;

  /** Số dư cuối kì */
  AfterBalance?: number;

  /** SĐt */
  Phone?: string;

  /** Loại KH 1 là KH, 2 đối tác */
  MainType?: number;

  /** Số tổng công nợ */
  TotalMoney?: number;

  /** ID POS */
  PosID?: number;

  /** ID AFF */
  AffID?: number;
}

export interface AppSummaryDebtListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: AppSummaryDebt[];
}

export interface AppUserView {
  /** Tài khoản */
  UserName?: string;

  /** Tên */
  FullName?: string;

  /** SĐt */
  Phone?: string;

  /** Ghi chú */
  Note?: string;

  /** Trạng thái */
  StatusName?: string;

  /** UserID */
  UserID?: number;

  /** Trạng thái 1: Hoạt động, 0 : khóa , -11:hủy */
  Status?: number;

  /** 4 : Kế toán , 5 : lái xe , 6 : phụ xe, 7 khác */
  GroupID?: number;

  /** Thời gian tạo */
  CreateDate?: Date;

  /**  */
  ShopName?: string;

  /**  */
  ShopID?: number;

  /**  */
  ShopIds?: number[];

  /** hoa hồng */
  Commission?: number;

  /**  */
  IsHideProfit?: number;
}

export interface AppUserViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: AppUserView[];
}

export interface BackMoneyFromCustomer {
  /**  */
  TotalMoney?: number;

  /**  */
  Note?: string;
}

export interface BankCardCreateItem {
  /**  */
  BankCardID?: number;

  /**  */
  BankID?: number;

  /**  */
  BankName?: string;

  /**  */
  NumberAccount?: string;

  /**  */
  BankCode?: string;

  /**  */
  AccountHolderName?: string;

  /**  */
  CreateDate?: Date;

  /**  */
  ModifyDate?: Date;

  /**  */
  Status?: number;
}

export interface BankCardCreateItemListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: BankCardCreateItem[];
}

export interface BankCardCreateRequest {
  /**  */
  BankCardID?: number;

  /**  */
  BankID?: number;

  /**  */
  NumberAccount?: string;

  /**  */
  AccountHolderName?: string;

  /**  */
  Status?: number;
}

export interface BankPartnerFee {
  /**  */
  BankID?: number;

  /**  */
  PercentVisa?: number;

  /**  */
  PercentMaster?: number;

  /**  */
  PercentNapas?: number;

  /**  */
  PercentJCB?: number;
}

export interface CardDataView {
  /** ID bản ghi */
  CardDataID?: number;

  /** ID KH */
  CustomerID?: number;

  /** ID ngân hàng */
  BankID?: number;

  /** Tên ngân hàng */
  BankName?: string;

  /** Hết hạn thẻ */
  ExpireDate?: string;

  /** Ghi chú */
  Note?: string;

  /** Ngày đáo hạn */
  DateDue?: number;

  /** Tiền quẹt */
  TotalMoney?: number;

  /** Số trên thẻ */
  CardNumber?: string;

  /** STK thẻ */
  AccountNumber?: string;

  /** % phí */
  PercentFee?: number;

  /** Trạng thái */
  Status?: number;

  /** Lợi nhuận tạm tính */
  RevenueExpected?: number;

  /** Tên Kh */
  CustomerName?: string;

  /** SĐT khách hàng */
  CustomerPhone?: string;

  /** địa chỉ khách hàng */
  CustomerAddress?: string;

  /** Loại thẻ */
  TypeCard?: number;

  /** Ngày tạo */
  CreateDate?: Date;

  /** Ngày sửa */
  ModifyDate?: Date;

  /** ID AffID */
  AffID?: number;

  /** % AFF */
  AffPercent?: number;

  /** Tên người hỗ trợ */
  StaffName?: string;

  /** Tên CTV */
  AffName?: string;

  /** ID nhân viên */
  StaffID?: number;

  /** Có để lại thẻ không */
  IsLeaveCard?: number;

  /** json url ảnh */
  FileImages?: string;

  /** json url ảnh */
  LstFileImages?: string[];

  /**  */
  OwnerCard?: string;
}

export interface CardDataViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: CardDataView[];
}

export interface CardDataViewResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: CardDataView;
}

export interface CfxPartnerBank {
  /**  */
  ID?: number;

  /**  */
  BankID?: number;

  /**  */
  PercentVisa?: number;

  /**  */
  PercentMaster?: number;

  /**  */
  PercentNapas?: number;

  /**  */
  PercentJCB?: number;

  /**  */
  CreateDate?: Date;

  /**  */
  CompanyID?: number;

  /**  */
  UserID?: number;

  /**  */
  PartnerParentID?: number;

  /**  */
  BankName?: string;
}

export interface CommentChange {
  /** ID đơn */
  ScheduleID?: number;

  /** Trạng thái */
  Status?: number;

  /** Comment */
  Comment?: string;
}

export interface CountNotify {
  /**  */
  CountCard?: number;
}

export interface CountNotifyResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: CountNotify;
}

export interface CreateAffAPI {
  /** ID Aff > 0 là sửa */
  AffID?: number;

  /** Tên AFF */
  AffName?: string;

  /** Mã code */
  AffCode?: string;

  /** Trạng thái */
  Status?: number;

  /** Tên đăng nhập */
  AccountName?: string;

  /** SĐT */
  Phone?: string;

  /** Mật khẩu */
  Password?: string;

  /** Phí thẻ visa */
  PercentVisa?: number;

  /** Phí thẻ master */
  PercentMaster?: number;

  /** Phí thẻ JSC */
  PercentJSC?: number;

  /**  */
  PercentNapas?: number;
}

export interface CreateCardDataRequest {
  /**  */
  CardDataID?: number;

  /**  */
  CustomerID?: number;

  /**  */
  BankID?: number;

  /**  */
  BankName?: string;

  /**  */
  ExpireDate?: string;

  /**  */
  Note?: string;

  /**  */
  DateDue?: number;

  /**  */
  TotalMoney?: number;

  /**  */
  CardNumber?: string;

  /**  */
  CardNumberBegin?: string;

  /**  */
  AccountNumber?: string;

  /**  */
  PercentFee?: number;

  /**  */
  Status?: number;

  /**  */
  RevenueExpected?: number;

  /**  */
  CustomerName?: string;

  /**  */
  CustomerPhone?: string;

  /**  */
  CustomerAddress?: string;

  /**  */
  TypeCard?: number;

  /**  */
  AffID?: number;

  /**  */
  AffPercent?: number;

  /**  */
  StaffID?: number;

  /**  */
  IsLeaveCard?: number;

  /**  */
  CardReceiptDate?: string;

  /**  */
  Source?: string;

  /**  */
  FileImages?: string[];

  /**  */
  OwnerCard?: string;
}

export interface CreateCardDataRequestAPI {
  /** ID bản ghi */
  CardDataID?: number;

  /** ID KH */
  CustomerID?: number;

  /** ID ngân hàng */
  BankID?: number;

  /** Tên ngân hàng */
  BankName?: string;

  /** Hết hạn thẻ */
  ExpireDate?: string;

  /** Ghi chú */
  Note?: string;

  /** Ngày đáo hạn */
  DateDue?: number;

  /** Tiền quẹt */
  TotalMoney?: number;

  /** Số trên thẻ */
  CardNumber?: string;

  /** Số trên thẻ */
  CardNumberBegin?: string;

  /** STK thẻ */
  AccountNumber?: string;

  /** % phí */
  PercentFee?: number;

  /** Trạng thái */
  Status?: number;

  /** Lợi nhuận tạm tính */
  RevenueExpected?: number;

  /** Tên Kh */
  CustomerName?: string;

  /** SĐT khách hàng */
  CustomerPhone?: string;

  /** địa chỉ khách hàng */
  CustomerAddress?: string;

  /** Loại thẻ */
  TypeCard?: number;

  /** ID CTV */
  AffID?: number;

  /** % AFF */
  AffPercent?: number;

  /** StaffID */
  StaffID?: number;

  /** Để lại thẻ hay k */
  IsLeaveCard?: number;

  /** list image */
  FileImages?: string[];

  /** chủ thẻ */
  OwnerCard?: string;
}

export interface CreateListSchedule {
  /**  */
  CardDataID?: number;

  /**  */
  TotalMoney?: number;

  /**  */
  PercentFee?: number;

  /**  */
  TypePayment?: number;

  /**  */
  AffID?: number;

  /**  */
  AffFeePercent?: number;

  /**  */
  NumberCode?: string;
}

export interface CreateListScheduleRequest {
  /**  */
  FromDate?: string;

  /**  */
  LstData?: CreateListSchedule[];
}

export interface CreateListScheduleResponse {
  /**  */
  ScheduleID?: number;
}

export interface CreatePosPartner {
  /** ID đối tác */
  PartnerID?: number;

  /** ID máy pos */
  PosID?: number;

  /** Phí thẻ visa */
  PercentVisa?: number;

  /** Phí thẻ master */
  PercentMaster?: number;

  /** Phí thẻ JSC */
  PercentJSC?: number;

  /** Phí thẻ JSC */
  PercentNapas?: number;
}

export interface CustomerCreate {
  /** ID KH = 0 là tạo . > 0 là sửa */
  CustomerID?: number;

  /** Tên KH */
  CustomerName?: string;

  /** SĐT */
  Phone?: string;

  /** Trạng thái */
  Status?: number;

  /** Địa chỉ */
  Address?: string;

  /** ID CTV */
  AffID?: number;

  /**  */
  ShopIDs?: number[];

  /**  */
  BankPartnerFees?: BankPartnerFee[];
}

export interface CustomerView {
  /** ID KH */
  CustomerID?: number;

  /** Tên KH */
  CustomerName?: string;

  /** SĐT */
  Phone?: string;

  /** Địa chỉ */
  Address?: string;

  /** Trạng thái */
  Status?: number;

  /** Thời gian tạo */
  CreateDate?: Date;

  /** Số thẻ đang có */
  TotalCard?: number;

  /** Số tiền */
  TotalMoney?: number;

  /** Danh sách thẻ */
  Cards?: string[];

  /** ID AffID */
  AffID?: number;

  /** % AFF */
  AffPercent?: number;

  /** Tên AFF */
  AffName?: string;

  /**  */
  PartnersShop?: PosShops[];

  /**  */
  PercentVisa?: number;

  /**  */
  PercentMaster?: number;

  /**  */
  PercentNapas?: number;

  /**  */
  PercentJCB?: number;

  /**  */
  BankPartnerFees?: CfxPartnerBank[];
}

export interface CustomerViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: CustomerView[];
}

export interface DateDetailMoneyCompany {
  /**  */
  ForDate?: Date;

  /**  */
  CashMoney?: number;

  /**  */
  DebtShop?: number;

  /**  */
  PosDebt?: number;

  /**  */
  TotalMoney?: number;

  /**  */
  PartnerDebt?: number;

  /**  */
  AffDebt?: number;

  /**  */
  CompanyCustomerDebt?: number;
}

export interface DeleteCardDataNotifyResponse { }

export interface DeleteCardDataNotifyResponseResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: DeleteCardDataNotifyResponse;
}

export interface DetailPaymentPartner {
  /**  */
  PosID?: number;

  /**  */
  TotalMoney?: number;

  /**  */
  TypeCard?: number;

  /**  */
  PercentPartner?: number;

  /**  */
  Note?: string;

  /**  */
  ShiftWorking?: number;

  /**  */
  BankID?: number;

  /**  */
  BillDateQuet?: Date;

  /**  */
  NumberLo?: number;
}

export interface EditUserRequest {
  /**  */
  FullName?: string;

  /**  */
  Phone?: string;
}

export interface ExpenseCardResponseAPI {
  /** ID bản ghi */
  ID?: number;

  /** ghi chú */
  Note?: string;

  /** số tiền */
  TotalMoney?: number;

  /** ngày tạo */
  CreateDate?: Date;

  /** Thời gian giao dịch */
  TransactionDate?: Date;

  /** Trạng thái */
  Status?: number;

  /** Tên đối tượng */
  Name?: string;

  /** thời gian hủy */
  CancelDate?: Date;

  /** Người tạo */
  UserCreate?: string;

  /** Người hủy */
  UserCancel?: string;
}

export interface ExpenseCardResponseAPIListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: ExpenseCardResponseAPI[];
}

export interface HistoryTopUp {
  /** Tổng tiền */
  TotalMoney?: number;

  /** Thời gian */
  TransactionDate?: Date;

  /** Nhân viên */
  UserName?: string;

  /** Loại thẻ */
  TypeMoney?: string;

  /**  */
  PaymentID?: number;
}

export interface HistoryTopUpListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: HistoryTopUp[];
}

export interface ImportPartnerExcelRequest {
  /**  */
  CustomerName?: string;

  /**  */
  Phone?: string;

  /**  */
  Address?: string;

  /**  */
  ShopIDs?: string;

  /**  */
  BankID1?: number;

  /**  */
  PercentVisa1?: number;

  /**  */
  PercentMaster1?: number;

  /**  */
  PercentNapas1?: number;

  /**  */
  PercentJCB1?: number;

  /**  */
  BankID2?: number;

  /**  */
  PercentVisa2?: number;

  /**  */
  PercentMaster2?: number;

  /**  */
  PercentNapas2?: number;

  /**  */
  PercentJCB2?: number;

  /**  */
  BankID3?: number;

  /**  */
  PercentVisa3?: number;

  /**  */
  PercentMaster3?: number;

  /**  */
  PercentNapas3?: number;

  /**  */
  PercentJCB3?: number;
}

export interface ImportPosExcelRequest {
  /**  */
  PosName?: string;

  /**  */
  BankID?: number;

  /**  */
  ShopID?: string;

  /**  */
  PercentVisa?: number;

  /**  */
  PercentMaster?: number;

  /**  */
  PercentJCB?: number;

  /**  */
  PercentNapas?: number;

  /**  */
  TID?: string;

  /**  */
  CashBackVisa?: number;

  /**  */
  CashBackMaster?: number;

  /**  */
  CashBackJCB?: number;

  /**  */
  CashBackNapas?: number;

  /**  */
  BankID1?: number;

  /**  */
  BankID2?: number;

  /**  */
  BankID3?: number;

  /**  */
  PercentVisa1?: number;

  /**  */
  PercentVisa2?: number;

  /**  */
  PercentVisa3?: number;

  /**  */
  PercentMaster1?: number;

  /**  */
  PercentMaster2?: number;

  /**  */
  PercentMaster3?: number;

  /**  */
  PercentJCB1?: number;

  /**  */
  PercentJCB2?: number;

  /**  */
  PercentJCB3?: number;

  /**  */
  PercentNapas1?: number;

  /**  */
  PercentNapas2?: number;

  /**  */
  PercentNapas3?: number;

  /**  */
  TotalMoney?: number;

  /**  */
  LimitMoney?: number;
}

export interface JSTreeModel {
  /** ID quyền */
  id?: string;

  /** Miêu tả quyền */
  text?: string;

  /**  */
  state?: State;

  /** Object con */
  children?: JSTreeModel[];
}

export interface JSTreeModelListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: JSTreeModel[];
}

export interface ListPosPayment {
  /**  */
  PosName?: string;

  /**  */
  PosID?: number;

  /**  */
  TotalMoney?: number;

  /**  */
  TotalMoneyInBill?: number;
}

export interface ListReportCompany {
  /**  */
  ForDate?: Date;

  /**  */
  ReportCompanies?: ReportCompany[];
}

export interface ListReportCompanyListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: ListReportCompany[];
}

export interface LogWithdrawalRequest {
  /**  */
  TotalMoney?: number;

  /**  */
  PosID?: number;

  /**  */
  ScheduleID?: number;
}

export interface ManualInputData {
  /**  */
  PosParentID?: number;

  /**  */
  PartnerID?: number;

  /**  */
  TotalMoneyInBill?: number;

  /**  */
  NumberLo?: string;

  /**  */
  TypeCard?: number;

  /**  */
  UserID?: number;

  /**  */
  Status?: number;

  /**  */
  TransactionDate?: string;
}

export interface MenuPermission {
  /**  */
  title?: string;

  /**  */
  visible?: boolean;

  /**  */
  children?: MenuPermission[];

  /**  */
  path?: string;

  /**  */
  position?: number;
}

export interface MetaBank {
  /** ID ngân hàng */
  BankID?: number;

  /** Tên ngân hàng */
  Code?: string;

  /** Tên ngân hàng */
  NameBank?: string;
}

export interface MetaBankListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: MetaBank[];
}

export interface MetaPercentAff {
  /**  */
  TypeCard?: number;

  /**  */
  Percent?: number;
}

export interface MetaPercentAffResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: MetaPercentAff;
}

export interface MetaStaff {
  /** UserID */
  UserID?: number;

  /** Tài khoản nhân viên */
  UserName?: string;
}

export interface MetaStaffListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: MetaStaff[];
}

export interface PartnerPaymentHistorySchedule {
  /**  */
  CustomerID?: number;

  /**  */
  PartnerID?: number;

  /**  */
  PartnerName?: string;

  /**  */
  TotalMoneyBill?: number;

  /**  */
  PartnerFee?: number;

  /**  */
  CustomerFee?: number;

  /**  */
  Profit?: number;

  /**  */
  DebtForPersion?: string;

  /**  */
  CreateDate?: Date;

  /**  */
  ScheduleID?: number;

  /**  */
  PaymentScheduleID?: number;

  /**  */
  MoneyInCard?: number;
}

export interface PartnerPaymentHistoryScheduleListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: PartnerPaymentHistorySchedule[];
}

export interface PayCashBackRequest {
  /**  */
  PosParentID?: number;

  /**  */
  TotalMoney?: number;

  /**  */
  Note?: string;
}

export interface PayNumberLoRequest {
  /**  */
  NumberLoID?: number;

  /**  */
  TotalMoney?: number;

  /**  */
  Note?: string;
}

export interface PaymentPartnerRequest {
  /**  */
  PartnerID?: number;

  /**  */
  PosID?: number;

  /**  */
  TotalMoney?: number;

  /**  */
  TypeCard?: number;

  /**  */
  PercentPartner?: number;

  /**  */
  TransactionDate?: Date;

  /**  */
  PaymentPartners?: DetailPaymentPartner[];

  /**  */
  NumberLo?: number;
}

export interface PaymentReciprocal {
  /** ID thẻ kh */
  CardID?: number;

  /** Tiền cần làm */
  TotalMoney?: number;

  /** tiền trên bill */
  TotalMoneyInBill?: number;

  /** Số tiền phí */
  CustomerFee?: number;

  /** % phí */
  PercentFee?: number;

  /** ID đối tác */
  PartnerID?: number;

  /** Loại hình : 1 là rút tiền ngay, 2 là gia công */
  ContractType?: number;

  /** Tiền khách trả */
  CustomerPay?: number;

  /** 0 là KH, 1 là ctv */
  DebtForPersion?: number;
}

export interface PaymentReciprocalNew {
  /** ID bản ghi */
  ScheduleID?: number;

  /** tiền trên bill */
  TotalMoneyInBill?: number;

  /** Số tiền phí */
  CustomerFee?: number;

  /** % phí */
  PercentFee?: number;

  /** ID đối tác */
  PartnerID?: number;

  /** 0 là KH, 1 là ctv */
  DebtForPersion?: number;

  /** % Tiền phí đối tác */
  PartnerFee?: number;

  /** Tiền phí */
  PartnerFeeMoney?: number;

  /**  */
  MoneyInCard?: number;
}

export interface PaymentSchedulePos {
  /**  */
  PosID?: number;

  /**  */
  TotalMoneyBill?: number;

  /** Phí bằng tiền */
  PercentMoney?: number;

  /**  */
  NumberLo?: number;

  /**  */
  NumberBill?: number;

  /**  */
  IsFeeFromCard?: number;

  /**  */
  PaymentScheduleID?: number;
}

export interface PosCreateAPI {
  /** ID bản ghi 0 là tạo */
  PosID?: number;

  /** Tên máy pos */
  PosName?: string;

  /** NGân hàng */
  BankID?: number;

  /** Phí thẻ visa */
  PercentVisa?: number;

  /** Phí thẻ master */
  PercentMaster?: number;

  /** Phí thẻ JSC */
  PercentJSC?: number;

  /** Phí thẻ Napas */
  PercentNapas?: number;

  /** Đối tác */
  PartnerID?: number;

  /** Tổng tiền */
  TotalMoney?: number;

  /** Giới hạn quẹt */
  LimitMoney?: number;

  /** Trạng thái */
  Status?: number;

  /** đối tác với case là ăn  % trên máy pos giao cho mình */
  PartnerCommissionID?: number;

  /** % đối tác được nhận trên pos */
  PartnerPercent?: number;

  /** Số tiền hàng tháng */
  PartnerMoneyPerMonth?: number;

  /**  */
  ShopIDs?: number[];

  /**  */
  CashBackVisa?: number;

  /**  */
  CashBackMaster?: number;

  /**  */
  CashBackJsc?: number;

  /**  */
  CashBackNapas?: number;

  /**  */
  IsCashBack?: number;

  /**  */
  TID?: string;

  /**  */
  PosCode?: string;

  /**  */
  UserNamePartner?: string;

  /**  */
  PoscodePartner?: string;

  /**  */
  BankPosFees?: BankPartnerFee[];
}

export interface PosFeePartner {
  /** ID bản ghi */
  ConfigPartnerFeeShopID?: number;

  /** Đối tác */
  PartnerID?: number;

  /** Phí */
  Fee?: number;

  /** Loại hình 1 là rút thẻ , 2 đáo hạn */
  TypePayment?: number;

  /** Trạng thái */
  Status?: number;
}

export interface PosMoney {
  /**  */
  PosID?: number;

  /**  */
  TotalMoney?: number;
}

export interface PosPartnerView {
  /** ID bản ghi */
  PosPartnerID?: number;

  /** ID pos */
  PosID?: number;

  /** ID đối tác */
  PartnerID?: number;

  /** Phí master */
  MasterFee?: number;

  /** Phí JCB */
  JCBFee?: number;

  /** Phí visa */
  VisaFee?: number;

  /** ID người tạo */
  CreateBy?: number;

  /** Ngày tạo */
  CreateDate?: Date;

  /** Thời gian cập nhật */
  ModifyDate?: Date;

  /** Trạng thái */
  Status?: number;

  /** Tên đối tác */
  PartnerName?: string;

  /** Tên máy pos */
  PosName?: string;

  /**  */
  NapasFee?: number;
}

export interface PosPartnerViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: PosPartnerView[];
}

export interface PosShops {
  /**  */
  ShopID?: number;

  /**  */
  ShopName?: string;
}

export interface PosViewAPI {
  /** ID bản ghi */
  PosID?: number;

  /** Tên pos */
  PosName?: string;

  /** ID ngân hàng */
  BankID?: number;

  /** Tên Ngân hàng */
  BankName?: string;

  /** Phí thẻ visa */
  PercentVisa?: number;

  /** Phí thẻ master */
  PercentMaster?: number;

  /** Phí thẻ JSC */
  PercentJSC?: number;

  /** ID đối tác */
  PartnerID?: number;

  /** tên đối tác */
  PartnerName?: string;

  /** Tổng tiền */
  TotalMoney?: number;

  /** Giới hạn quẹt */
  LimitMoney?: number;

  /** Trạng thái */
  Status?: number;

  /** NGày tạo */
  CreateDate?: Date;

  /** đối tác với case là ăn  % trên máy pos giao cho mình */
  PartnerCommissionID?: number;

  /** % đối tác được nhận trên pos */
  PartnerPercent?: number;

  /**  */
  PartnerMoneyPerMonth?: number;

  /**  */
  PercentNapas?: number;

  /**  */
  PosShops?: PosShops[];

  /**  */
  CashBackVisa?: number;

  /**  */
  CashBackMaster?: number;

  /**  */
  CashBackJsc?: number;

  /**  */
  CashBackNapas?: number;

  /**  */
  IsCashBack?: number;

  /**  */
  TID?: string;

  /**  */
  PosCode?: string;

  /**  */
  UserNamePartner?: string;

  /**  */
  PosCodePartner?: string;

  /**  */
  UserIDPartner?: number;

  /**  */
  PosParentOfPartnerID?: number;

  /**  */
  BankPosFees?: TblCfxPosParentBank[];
}

export interface PosViewAPIListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: PosViewAPI[];
}

export interface ReportAllShop {
  /**  */
  MoneyInBill?: number;

  /**  */
  MoneyFee?: number;

  /**  */
  MoneyPosFee?: number;

  /**  */
  ShopID?: number;

  /**  */
  ShopName?: string;

  /**  */
  Revenue?: number;

  /**  */
  TotalCard?: number;

  /**  */
  TotalCardHold?: number;
}

export interface ReportAllShopListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: ReportAllShop[];
}

export interface ReportCompany {
  /**  */
  TotalInBill?: number;

  /**  */
  PosFee?: number;

  /**  */
  FeeInBill?: number;

  /**  */
  ForDate?: Date;

  /**  */
  MoneyBegin?: number;

  /**  */
  ShopID?: number;

  /**  */
  ShopName?: string;

  /**  */
  CollectOther?: number;

  /**  */
  ExpenseOther?: number;

  /**  */
  Profit?: number;

  /**  */
  MoneySentBank?: number;

  /**  */
  TotalCountData?: number;

  /**  */
  AffFeeMoney?: number;

  /**  */
  ExpenseBadDebt?: number;
}

export interface ReportDebtCompany {
  /**  */
  ShopID?: number;

  /**  */
  ShopName?: string;

  /**  */
  PosDebtNeedCollect?: number;

  /**  */
  PosDebtNeedPay?: number;

  /**  */
  MoneyInBill?: number;

  /**  */
  PosFee?: number;

  /**  */
  CustomerFee?: number;

  /**  */
  PosPayment?: number;

  /**  */
  BeginMoney?: number;

  /**  */
  LastMoney?: number;
}

export interface ReportDebtCompanyListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: ReportDebtCompany[];
}

export interface ReportSaleCompany {
  /**  */
  CustomerFee?: number;

  /**  */
  PosFee?: number;

  /**  */
  AffFee?: number;

  /**  */
  TotalCapitalFee?: number;

  /**  */
  ExpenseOther?: number;

  /**  */
  CollectOther?: number;

  /**  */
  Revenue?: number;

  /**  */
  ExpenseMoneyManager?: number;

  /**  */
  ExpenseBadDebt?: number;

  /**  */
  ExpenseAff?: number;
}

export interface ReportSaleCompanyResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: ReportSaleCompany;
}

export interface RequestAddExpenseCard {
  /**  */
  Note?: string;

  /**  */
  TotalMoney?: number;

  /**  */
  TransactionDate?: Date;

  /**  */
  Name?: string;
}

export interface RequestManualInputData {
  /**  */
  Datas?: ManualInputData[];
}

export interface RequestMoneyBack {
  /**  */
  TotalMoney?: number;

  /**  */
  DateData?: string;

  /**  */
  ShopID?: number;
}

export interface RequestMoneyBegin {
  /**  */
  TotalMoney?: number;

  /**  */
  ShopID?: number;
}

export interface ResponseData {
  /**  */
  Result?: number;

  /**  */
  Message?: string;

  /**  */
  Data?: any | null;

  /**  */
  TotalData?: any | null;

  /**  */
  TotalCount?: number;
}

export interface ResponseReportMoneyCompany {
  /**  */
  MoneyBegin?: number;

  /**  */
  MoneyEnd?: number;

  /**  */
  CollectMoney?: number;

  /**  */
  ExpenseMoney?: number;

  /**  */
  ExpenseMoneyManager?: number;

  /**  */
  CollectCapitalMoney?: number;

  /**  */
  ExpenseCapitalMoney?: number;

  /**  */
  ExpenseDebtAff?: number;

  /**  */
  ProfitMoney?: number;

  /**  */
  AdjustDebtMoney?: number;

  /**  */
  DetailMoneyCompanies?: DateDetailMoneyCompany[];

  /**  */
  CompanyCustomerDebt?: number;
}

export interface ResponseReportMoneyCompanyResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: ResponseReportMoneyCompany;
}

export interface SelectModel {
  /** ID bản ghi */
  ID?: number;

  /** Value Select */
  Text?: string;
}

export interface SelectModelCard {
  /** ID bản ghi */
  ID?: number;

  /** Value Select */
  Text?: string;

  /**  */
  TotalMoney?: number;

  /**  */
  PercentCustomer?: number;
}

export interface SelectModelCardListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: SelectModelCard[];
}

export interface SelectModelListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: SelectModel[];
}

export interface SentMoneyCustomer {
  /** ID Bản ghi */
  ScheduleID?: number;

  /** ID pos */
  CardID?: number;

  /** So tien */
  TotalMoney?: number;

  /** 0 là KH, 1 là ctv, 2 là đối tác */
  DebtForPersion?: number;
}

export interface ShopUserLogin {
  /** SHOPID */
  ShopID?: number;

  /** Tên cửa hàng */
  ShopName?: string;

  /** Địa chỉ */
  Address?: string;
}

export interface State {
  /** đóng hay mở tree */
  opened?: boolean;

  /** disable hay k */
  disabled?: boolean;

  /** Tích hay k */
  selected?: boolean;
}

export interface TblCfxPosParentBank {
  /**  */
  ID?: number;

  /**  */
  PosParentID?: number;

  /**  */
  BankID?: number;

  /**  */
  PercentVisa?: number;

  /**  */
  PercentMaster?: number;

  /**  */
  PercentNapas?: number;

  /**  */
  PercentJCB?: number;

  /**  */
  CreateDate?: Date;

  /**  */
  CompanyID?: number;

  /**  */
  UserID?: number;
}

export interface TblHistorySentBankCard {
  /**  */
  HistorySentBankCardID?: number;

  /**  */
  ScheduleID?: number;

  /**  */
  CardDataID?: number;

  /**  */
  TotalMoney?: number;

  /**  */
  UserID?: number;

  /**  */
  CreateDate?: Date;

  /**  */
  Status?: number;

  /**  */
  UserName?: string;

  /**  */
  CustomerID?: number;

  /**  */
  AffID?: number;

  /**  */
  ShopID?: number;
}

export interface TblHistorySentBankCardListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: TblHistorySentBankCard[];
}

export interface UpdateNoteScheduleRequest {
  /**  */
  Note?: string;

  /**  */
  ScheduleID?: number;
}

export interface UpdateNumberLoRequest {
  /**  */
  Name?: string;

  /**  */
  ShopIds?: number[];

  /**  */
  PosParentID?: number;

  /**  */
  ID?: number;
}

export interface UpdateStatusLogPosCodePartnerRequest {
  /**  */
  LogPosCodePartnerID?: number;

  /**  */
  Status?: number;
}
